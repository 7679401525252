import { createStore } from "vuex";

export default createStore({
  state: {
    hallCode: ''
  },
  mutations: {
    SAVE_HALL_CODE(state, payload) {
      state.hallCode = payload
    },
  },
  actions: {},
  modules: {}
});
